<template>
    <div class="main">
        <div class="item1">
            <el-empty description="网站正在维护" />
        </div>
        <div class="item2">
            <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023001175号-1</a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.main{
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.item1{
    flex: 1;
    display: flex;
    justify-content: center;
}
.item2{
    font-size: 15px;
    text-align: center;
    padding-bottom: 10px;
}
a{
    color: #000;
    text-decoration: none;
}
</style>
